import './App.css';

import Salats from './comps/salats';

function App() {
  return (
    <div className="App">
  <Salats/>
    </div>
  );
}

export default App;
