import { configureStore } from "@reduxjs/toolkit";

import { salatsApi } from "../services/salatsAPI";

export default configureStore ({
    reducer:{
        [salatsApi.reducerPath]:salatsApi.reducer
    },

    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(salatsApi.middleware),
});