import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const SalatApiHeaders = {

    'content-type': 'application/octet-stream',
    'X-RapidAPI-Key': '380360489bmshf5a9e387a8175e9p11cd3djsn242cbd645d53',
    'X-RapidAPI-Host': 'muslimsalat.p.rapidapi.com'
};

// const baseUrl = 'https://muslimsalat.p.rapidapi.com/cairo.json';
// const baseUrl = 'https://muslimsalat.p.rapidapi.com/banha.json';
const baseUrl = 'https://muslimsalat.p.rapidapi.com/';


const createRequest = (city)=>({url:`${baseUrl}${city}.json`,headers:SalatApiHeaders})




export const salatsApi = createApi({
    reducerPath:'salatsApi',
    baseQuery:fetchBaseQuery({baseUrl}),
    endpoints:(builder)=> ({
        getSalats:builder.query({
            query:(city)=>createRequest(city)
        })
    })
})


export const {useGetSalatsQuery} = salatsApi;