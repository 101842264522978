const egyptGovernhhorates = [];

/////////////

export const governorates = [
  {
    name: "Alexandria",
    cities: [
      "Alexandria",
      "Marsa Matrouh",
    ],
  },
  {
    name: "Aswan",
    cities: ["Aswan", "Daraw"],
  },

  {
    name: "Asyut",
    cities: ["Asyut", "Abnub", "Abu Qurqas", "Manfalut"],
  },
  {
    name: "Beheira",
    cities: ["Damanhur", "Kafr al-Dawwar", "Kom Hamada", "Kafr El Sheikh"],
  },
  {
    name: "Beni Suef",
    cities: ["Beni Suef"],
  },
  {
    name: "Cairo",
    cities: [
      "Cairo",
      "Abdeen",
      "Agouza",
      "Al-Azhar",
      "Boulaq",
      "Dokki",
      "Maadi",
      "Mokattam",
      "Nasr City",
      "New Cairo",
      "Zamalek",
      "Madinaty",
      "Al obour"
    ],
  },
  {
    name: "Dakahlia",
    cities: ["Mansoura", "Aga", "Dekernes", "Gamasa", "Mit Ghamr", "Talkha"],
  },
  {
    name: "Damietta",
    cities: ["Damietta", "Kafr Saad"],
  },
  {
    name: "Faiyum",
    cities: ["Faiyum", "Sinnuris"],
  },
  {
    name: "Gharbia",
    cities: ["Tanta"],
  },
  {
    name: "Giza",
    cities: [
      "Giza",
      "6th of October City",
      "Abu Rawash",
      "Imbaba",
      "Al Agouzah",
      "Kerdasa",
      "Omrania",
    ],
  },
  {
    name: "Ismailia",
    cities: ["Ismailia", "Fayed"],
  },
  {
    name: "Kafr El Sheikh",
    cities: ["Kafr El Sheikh", "Damanhour"],
  },
  {
    name: "Luxor",
    cities: ["Luxor"],
  },
  {
    name: "Matrouh",
    cities: ["Marsa Matrouh"],
  },
  {
    name: "Minya",
    cities: ["Minya"],
  },
  {
    name: "Monufia",
    cities: ["Sadat City"],
  },
  {
    name: "New Valley",
    cities: ["Kharga" ],
  },
  {
    name: "North Sinai",
    cities: ["Rafah"],
  },
  {
    name: "Port Said",
    cities: ["Port Said"],
  },
  {
    name: "Qalyubia",
    cities: ["Banha", "Qalyub", "Shubra","toukh"],
  },
  {
    name: "Qena",
    cities: ["Qena"],
  },
  {
    name: "Red Sea",
    cities: ["Hurghada", "Marsa Alam"],
  },
  {
    name: "Sharqia",
    cities: ["Zagazig", "10th of Ramadan City"],
  },
  {
    name: "Sohag",
    cities: ["Sohag", "Akhmim"],
  },
  {
    name: "South Sinai",
    cities: ["Sharm El Sheikh", "Dahab"],
  },
  {
    name: "Suez",
    cities: ["Suez"],
  },
];
