import React  from 'react'

const Footer = () => {

    const data = new Date()

   return (
    <div className=' flex flex-col md:w-full w-[450px] bg-gray-100  font-semibold h-[60px]'>
         <p>Al Tarek</p>
        <p> Copyright ⓒ {data.getFullYear()}</p>
    </div>
  )
}

export default Footer