import React, { useEffect, useState } from "react";

import { useGetSalatsQuery } from "../services/salatsAPI";

import { governorates } from "../cities/index";

import Salat from "./salat";

import Footer from "./Footer";

// import mosque from '../../public/image/mosque.jpg'

const Salats = () => {
  const [selectedGovernorate, setSelectedGovernorate] = useState("cario");

  const [selectedCity, setSelectedCity] = useState("cario");

  const { data: salats, isLoading, isError } = useGetSalatsQuery(selectedCity);

  const handleGovernorateChange = (event) => {
    setSelectedGovernorate(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setSelectedCity(e.target.elements[1].value);

  //   console.log(selectedCity);
  // };

  const cities = governorates.find(
    (governorate) => governorate.name === selectedGovernorate
  )?.cities;

  useEffect(() => {
    console.log(salats);
  }, [salats]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error retrieving salat times.</div>;
  }

  return (
    <div className="md:w-[1540px] w-[375px] h-full flex flex-col">
      <div className="   flex flex-col justify-center  md:gap-20 gap-3 md:w-full w-[450px] md:h-[650px]  h-[750px]  bg-cover  bg-no-repeat bg-center  bg-[url('/public/image/mosque.jpg')]   ">
        <h1 className="md:text-4xl text-2xl font-semibold  text-center  text-white">
          مواقيت الصلاه في مصر
        </h1>

        <form
          // onSubmit={handleSubmit}
          className="  mt-5  mb-20  flex md:flex-row flex-col md:justify-center  md:items-end items-center md:gap-[100px] gap-5 "
        >
          <label className="text-base font-semibold text-right ">
            {" "}
            <p className="text-white font-bold"> : اختار المحافظه </p>
            <select
              className=" w-[150px] py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 "
              value={selectedGovernorate}
              onChange={handleGovernorateChange}
            >
              <option value="">{/* : اختار المحافظه  */}</option>

              {governorates.map((governorate) => {
                return (
                  <option key={governorate.name} value={governorate.name}>
                    {governorate.name}
                  </option>
                );
              })}
            </select>
          </label>
          {cities && (
            <label className="text-base font-semibold text-right ">
              <p className="text-white font-bold"> : اختار المدينه</p>
              <select
                className="w-[150px] py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={selectedCity}
                onChange={handleCityChange}

              >
                <option value="">{/* : اختار المدينه  */}</option>

                {cities.map((city) => {
                  return (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </label>
          )}

          {/* <button
            className="w-[150px] h-[40px]    py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500
          text-base font-semibold 
          "
            type="submit"
          >
            Submit
          </button> */}
        </form>
        <div className="">
          {isLoading && <div>Loading...</div>}
          {isError && <div>Error</div>}
          {salats && (
            <div className=" m-auto flex flex-col align-center justify-center ">
              {/* <h2>{salats.title}</h2> */}
              <div
                className="flex md:flex-row flex-col
            
            flex-1
            
             m-auto"
              >
                <Salat name="الشروق" time={salats?.items[0].shurooq} />
                <Salat name="الفجر" time={salats?.items[0].fajr} />
                <Salat name="الظهر" time={salats?.items[0].dhuhr} />
                <Salat name="العصر" time={salats?.items[0].asr} />
                <Salat name="المغرب" time={salats?.items[0].maghrib} />
                <Salat name="العشاء" time={salats?.items[0].isha} />
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Salats;
