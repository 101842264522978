import React from 'react'

const Salat = (props) => {

    function addOneHour(timeString) {
        const [time, period] = timeString.split(" ");
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);
      
        if (hours === 12) {
          // if it's 12 am or pm, set hours to 0 or 12 respectively
          hours = period === "am" ? 0 : 12;
        } else if (period === "pm") {
          // if it's pm, add 12 to hours
          hours += 12;
        }
      
        // add one hour to hours
        hours = (hours + 1) % 24;
      
        // format the new time string
        const newPeriod = hours < 12 ? "am" : "pm";
        hours = ((hours + 11) % 12 + 1).toString().padStart(2, "0");
        minutes = minutes.toString().padStart(2, "0");
        return `${hours}:${minutes} ${newPeriod}`;
      }
      
  return (
    <div className='w-[200px] text-center text-white'>
<h2 className='text-base font-bold' >{ addOneHour(props.time)} : <span className='text-base font-semibold	'>
 {/* <span className='md:block '>:</span>  */}
 
  {props.name}</span></h2>
    </div>
  )
}

export default Salat